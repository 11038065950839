const inter = [
    {
      space_src: "baidujs",
      space_type: "inters",
      space_id: "u6981373",
      space_js: "//jffpbdmym.9fenmedia.cn/common/p/kj/source/sm-tcl/static/c.js"
    }
  ];
  
  const banner = [
    {
      space_src: "baidujs",
      space_type: "inters",
      space_id: "u6981375",
      space_js: "//jffpbdmym.9fenmedia.cn/source/rml/common/u_ov/production/en/static/j.js"
    }
  ];
  
  const Native1 = [
    {
      space_src: "baidujs",
      space_type: "native",
      space_id: "u6981374",
      space_js: "//jffpbdmym.9fenmedia.cn/production/qlk/production/tn/u-dm/common/h.js"
    }
  ];
  
  const Native2 = [
    {
      space_src: "baidujs",
      space_type: "native",
      space_id: "u6981376",
      space_js: "//jffpbdmym.9fenmedia.cn/site/sn/production/m/v/openjs/pwfo-m.js"
    }
  ];
  
  const Native3 = [
    {
      space_src: "baidujs",
      space_type: "native",
      space_id: "u6981377",
      space_js: "//jffpbdmym.9fenmedia.cn/common/t/onw/production/q_xg/pp.js"
    }
  ];

  const SPACE_DATA = {

  };
  export const SPACE_MAP = {
    HomeInter: inter,
    // HomeDialog: Native1,
    // HomeNativeInter: Native1,
    HomeBanner: banner,
    HomeNative: Native1,
    HomeNativeCenter: Native2,
    HomeNativeBottom: Native3,
    HotInter: inter,
    HotBanner: banner,
    HotNative: Native1,
    DetailInter: inter,
    DetailBanner: banner,
    DetailNative: Native1,
    DetailNativeCenter: Native2,
    DetailNativeBottom: Native3,
}
  