import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

import FixedBackHome from "./components/common/FixedBackHome.vue";
import wapInter from "@haa/fwk-h5/components/wap-inter.vue";

import { computed, ref, provide, nextTick } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useControlStore } from "./store/control";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { kepuInter } = storeToRefs(useControlStore());

const route = useRoute();

// 是否展示返回首页全局浮窗按钮
const showFixedBackHome = computed<boolean>(() => {
  // 刷新后 route.matched第一时间是空数组
  let flag = !!route.matched?.[0];

  // 判断路由表上是否携带隐藏返回首页icon字段
  if (route.matched?.[0]?.meta.hasOwnProperty("hideFixedHome")) {
    flag = route.matched[0].meta.hideFixedHome as boolean;
  }

  return flag;
});

// 注入刷新router-view组件函数
// router.replace 跳转自己时不会刷新页面 触发不了页面打点
// 所以需要借用刷新函数
const routeActive = ref(true);
const refreshRouterView = () => {
  routeActive.value = false;
  nextTick(() => {
    routeActive.value = true;
  });
};

provide("refreshRouterView", refreshRouterView);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (routeActive.value)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(FixedBackHome, null, null, 512), [
      [_vShow, showFixedBackHome.value]
    ])
  ]))
}
}

})