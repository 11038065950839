<template>
  <div class="app">
    <router-view v-if="routeActive" />

    <FixedBackHome v-show="showFixedBackHome"></FixedBackHome>

    <!-- <wapInter v-if="kepuInter" space-key="TopicKepuInter"></wapInter> -->
  </div>
</template>

<script lang="ts" setup>
import FixedBackHome from "./components/common/FixedBackHome.vue";
import wapInter from "@haa/fwk-h5/components/wap-inter.vue";

import { computed, ref, provide, nextTick } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useControlStore } from "./store/control";

const { kepuInter } = storeToRefs(useControlStore());

const route = useRoute();

// 是否展示返回首页全局浮窗按钮
const showFixedBackHome = computed<boolean>(() => {
  // 刷新后 route.matched第一时间是空数组
  let flag = !!route.matched?.[0];

  // 判断路由表上是否携带隐藏返回首页icon字段
  if (route.matched?.[0]?.meta.hasOwnProperty("hideFixedHome")) {
    flag = route.matched[0].meta.hideFixedHome as boolean;
  }

  return flag;
});

// 注入刷新router-view组件函数
// router.replace 跳转自己时不会刷新页面 触发不了页面打点
// 所以需要借用刷新函数
const routeActive = ref(true);
const refreshRouterView = () => {
  routeActive.value = false;
  nextTick(() => {
    routeActive.value = true;
  });
};

provide("refreshRouterView", refreshRouterView);
</script>

<style lang="less">
.app {
  width: 750px;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 24px;
}
</style>
