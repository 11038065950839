import { isRef, ref, reactive, computed, onUnmounted, watch, nextTick, type Ref, type App } from "vue";
import { useRequest, type Service } from "vue-request";
import { Haa } from "@haa/fwk-h5/lib/index";
import router from "@/router";
import { useRoute } from "vue-router";
import bus from "@haa/fwk-h5/lib/bus";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { useControlStore } from "@/store/control";
import { useCategoryStore } from "@/store/category";
import { useIntervalFn, useDocumentVisibility } from "@vueuse/core";
import { SPACE_MAP } from '../config/ad_config';

/** 初始化用户信息hook */
export const useInit = (app: App<Element>) => {
  // 初始化uuid与query
  useUserStore().init();

  // 获取分类数据
  useCategoryStore().getCategory();

  /** 初始化sdk */
  let cfg = {
    queryMap: {
      channel: "channel",
      subchannel: "subchannel",
      key: "key"
    },
    router: router,
    home: ["Home", "HomeSpecial"],
    app: app,
    spaceMap: SPACE_MAP,
    deepStart: process.env.VUE_APP_START == "guide" ? 1 : 2
  };
  Haa.init(cfg);
};

/** 首页信息hook */
export const useTab = <T>(tabList: T[] | Ref<T[]>, active: Ref<number> | number = 0) => {
  const _active = isRef(active) ? active : ref(active);

  const _currTab = computed(() => {
    return (isRef(tabList) ? tabList.value[_active.value] : tabList[_active.value]) ?? undefined;
  });

  return {
    active: _active,
    currTab: _currTab
  };
};

// 返回逻辑处理
export const useBack = () => {
  const route = useRoute();

  const routerBack = () => {
    if (route.query.return == "1" || route.params.first == "1") {
      return router.replace({ path: "/home" });
    }

    router.back();
  };

  return {
    routerBack
  };
};

// 初始化插屏展示
export const useInitInter = (
  visibleKeys: { dialogKey: string; interKey: string; nativeInterKey: string },
  callBack?: {
    before?: () => boolean;
    after?: (flag: boolean) => void;
  }
) => {
  // 列出要展示的插屏 与 弹窗
  const visible: { [key: string]: Ref<boolean> } = {
    [visibleKeys.dialogKey]: ref(false),
    [visibleKeys.interKey]: ref(false),
    [visibleKeys.nativeInterKey]: ref(false)
  };

  // 策略信息
  const { strategy } = Haa.util().useStrategy();

  // 初始化插屏
  const initInter = () => {
    if (strategy.status == 0) return;

    // 策略请求失败
    if (strategy.status == 2) {
      visible.HomeDialog.value = true;
      return;
    }

    const flag = Object.keys(strategy.h5_ad_space_config).some((key) => {
      const flag = Object.keys(visible).includes(key);

      if (flag) {
        visible[key].value = true;
      }

      return flag;
    });

    callBack?.after?.(flag);
  };

  if (!callBack?.before || (callBack?.before && callBack?.before())) {
    initInter();

    bus.on("strategy", initInter);

    onUnmounted(() => {
      bus.off("strategy", initInter);
    });
  }

  return {
    dialogVisible: visible[visibleKeys.dialogKey],
    interVisible: visible[visibleKeys.interKey],
    nativeInterVisible: visible[visibleKeys.nativeInterKey]
  };
};

/** 通用 加载更多列表 hook
 * T: 指定列表类型
 * U: 指定格式化后的列表类型 搭配formatHandler使用
 * requestHandler: 请求的api接口
 * options: IUseOptions<T, U>
 */
interface IUseOptions<T, U> {
  /** 是否初始加载数据 */
  initload?: boolean;
  /** 请求时另外的参数 */
  params?: { [key: string]: any };
  /** 格式化返回数据函数 */
  formatHandler?: (arg: T) => U;
}
export const useLoadList = <T, U = never>(requestHandler: Service<unknown, any>, options: IUseOptions<T, U> = {}) => {
  type K = [U] extends [never] ? T : U;

  let list = ref<K[]>([]);

  const initload = options.initload ?? true;

  const _params: { [key: string]: any } = ref({
    ...(options.params ?? {})
  });

  const pagination = reactive<Pagination>({
    page: 0,
    limit: 10,
    total: null
  });

  const _pagination = computed(() => ({
    page: pagination.page + 1,
    limit: pagination.limit,
    // 传入的额外参数
    ..._params.value
  }));

  /** 列表状态 begin */
  const _error = ref(false);
  const _refresh = ref(false);
  const finished = computed(() => pagination.total != null && pagination.total === list.value.length); // 没有更多
  const isInit = computed(() => !!list.value.length); // 是否初始化数据完成，列表中有数据代表初始化已完成
  /** 列表状态 end */

  const { data, loading, runAsync } = useRequest(requestHandler, {
    manual: true
  });

  // 判断是否初始加载数据
  if (initload) {
    // 初始先赋值true 防止vant list组件初始触发 onload事件
    loading.value = true;
    runAsync({ ..._pagination.value }).catch((e) => {
      _error.value = true;
    });
  }

  watch(
    () => data.value as Pagination & { data: T[] },
    (val) => {
      pagination.total = val?.total || null;
      pagination.page = val?.page;

      const formatData = options.formatHandler ? val?.data.map(options.formatHandler) : val?.data || [];
      Array.prototype.push.apply(list.value, formatData);
    }
  );

  const setParmas = (params: { [key: string]: any }) => {
    Object.assign(_params.value, params);
  };

  // 请求更多数据
  const onRequestMore = () => {
    if (finished.value) return;
    if (loading.value) return;
    if (_refresh.value) return;
    if (_error.value) return;

    return runAsync({ ..._pagination.value })
      .then(() => {
        _error.value = false;
      })
      .catch(() => {
        _error.value = true;
      });
  };

  // 刷新数据
  const onRefresh = (params: { [key: string]: any }) => {
    if (_refresh.value) return;
    _refresh.value = true;

    // 回到初始值
    pagination.page = 0;
    pagination.limit = 10;
    pagination.total = null;
    list.value = [];

    setParmas(params);

    return runAsync({ ..._pagination.value })
      .then(() => {
        _error.value = false;
      })
      .catch(() => {
        _error.value = true;
      })
      .finally(() => {
        _refresh.value = false;
      });
  };

  return {
    list,
    pagination,
    loading,
    error: _error,
    finished,
    isInit,
    setParmas,
    onRequestMore,
    onRefresh
  };
};

interface ICountDownOptions {
  // 隐藏页面是否需要暂停定时器
  hiddenPause: boolean;
}
// 倒计时hooks
export const useCountDown = (delay: number = 1000, options: Partial<ICountDownOptions> = {}) => {
  const count = ref(0);

  const _options: ICountDownOptions = {
    hiddenPause: true,
    ...options
  };

  const finishedHandler = ref(() => {});

  const { pause, resume } = useIntervalFn(
    () => {
      count.value--;

      if (count.value <= 0) {
        finishedHandler?.value();

        pause();
      }
    },
    delay,
    { immediate: false }
  );

  const onStart = (num: number) => {
    count.value = num;
    pause();
    nextTick(resume);
  };

  // 隐藏页面是否需要暂停定时器
  if (_options.hiddenPause) {
    const visiblity = useDocumentVisibility();

    watch(visiblity, (val) => {
      // 确保页面隐藏时暂停定时 不论count是否为0
      if (val === "hidden") {
        pause();
      } else if (val === "visible") {
        if (count.value == 0) return;
        resume();
      }
    });
  }

  return {
    count,
    finishedHandler,
    onStart,
    pause
  };
};
